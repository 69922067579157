import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import CustomStatusSelect from './CustomStatusSelect'
import NFCHistoryTable from './NFCHistoryTable'
import { Props, RouteParams, UpdateNFCStatusForm } from './NFCRequestHistoryType'

const NFCRequestHistory: FC<Props> = ({ data, handleIsBalanseChanged }) => {
  const { id } = useParams<RouteParams>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { register, handleSubmit, reset, setValue, watch } = useForm<UpdateNFCStatusForm>()
  const statusValue = watch('status')

  // ------------------------------- get history -------------------------------
  const {
    data: GetHistoryData,
    refetch: refetchGetHistory,
    isLoading: GetHistoryIsLoading,
  } = useQuery(['nfc-history', id], () => fetch('GET', `/nfc-replacement-requests/logs/${id}`, {}), {
    enabled: !!id,
    staleTime: 3000,
  })
  // ---------------------------------------------------------------------------

  // ------------------------------- update status -----------------------------
  const {
    isLoading: UpdateStatusIsLoading,
    mutate: UpdateStatusMutate,
    data: updateStatusData,
  } = useMutation((vars: any) => fetch('POST', `/nfc-replacement-requests/update-status`, { data: vars }), {
    onSuccess: (): void => {
      onClose()
      refetchGetHistory()
    },
    onError: () => {},
  })
  // ---------------------------------------------------------------------------

  const onSubmit = (data: UpdateNFCStatusForm) => {
    UpdateStatusMutate({
      ...data,
      id: Number(id),
    })
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  // --------------- Push a value into options if it satisfies the condition -------------------------------------------------------
  const getAvailableOptions = () => {
    const statusData = GetHistoryData ? GetHistoryData[0] : data
    const options = []
    // --------- In order for In Progress to be displayed, the status must not be DONE, REJECTED, IN_PROGRESS, or DELIVERED ---------
    if (statusData?.status !== 'DONE' && statusData?.status !== 'REJECTED' && statusData?.status !== 'IN_PROGRESS' && statusData?.status !== 'DELIVERED') {
      options.push({ value: 'IN_PROGRESS', label: dictionary().NFC_STATUS_IN_PROGRESS })
    }
    // --------- In order for Awaiting Review to be displayed, the status must not be REJECTED, DONE, or Awaiting Review ------------
    if (
      statusData?.status !== 'READY_FOR_DELIVERY' &&
      statusData?.status !== 'REJECTED' &&
      statusData?.status !== 'DONE' &&
      statusData?.status !== 'DELIVERED'
    ) {
      options.push({ value: 'READY_FOR_DELIVERY', label: dictionary().NFC_STATUS_READY_FOR_DELIVERY })
    }
    // --------- In order for Delivered to be displayed, the status must not be REJECTED, DONE, or DELIVERED ------------------------
    if (statusData?.status !== 'DELIVERED' && statusData?.status !== 'REJECTED' && statusData?.status !== 'DONE') {
      options.push({ value: 'DELIVERED', label: dictionary().NFC_STATUS_DELIVERED })
    }
    // --------- In order for Done to be displayed, the status must not be REJECTED or DONE -----------------------------------------
    if (statusData?.status !== 'REJECTED' && statusData?.status !== 'DONE') {
      options.push({ value: 'DONE', label: dictionary().NFC_STATUS_DONE })
    }
    // --------- In order for Rejected to be displayed, the status must not be DONE or REJECTED -------------------------------------
    if (statusData?.status !== 'DONE' && statusData?.status !== 'REJECTED') {
      options.push({ value: 'REJECTED', label: dictionary().NFC_STATUS_REJECTED })
    }
    return options
  }
  // -------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card display={'flex'} p={3} mt={3} flexDirection={'column'}>
        <Flex display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
          <Text m={3} fontSize='20px'>
            {dictionary().STATUS_DATE}
          </Text>
          <Button
            variant={'outline'}
            size='lg'
            h='48px'
            px={6}
            onClick={onOpen}
            disabled={
              GetHistoryData
                ? GetHistoryData[0]?.status === 'DONE' || GetHistoryData[0]?.status === 'REJECTED'
                : data?.status === 'DONE' || data?.status === 'REJECTED'
            }
          >
            {dictionary().CHANGE_STATUS}
          </Button>
        </Flex>

        <div style={{ width: '100%', height: '1px', backgroundColor: '#E2E8F0', marginBottom: '15px', marginTop: '15px' }} />

        <NFCHistoryTable GetHistoryData={GetHistoryData} isLoading={GetHistoryIsLoading || UpdateStatusIsLoading} />
      </Card>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent dir='rtl'>
          <ModalHeader textAlign='right'>{dictionary().CHANGE_STATUS}</ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl mb={4} isRequired textAlign='right'>
                <CustomStatusSelect
                  value={statusValue}
                  onChange={(value) =>
                    setValue('status', value as 'DONE' | 'IN_PROGRESS' | 'REJECTED' | 'AWAITING_REVIEW' | 'READY_FOR_DELIVERY' | 'DELIVERED')
                  }
                  options={getAvailableOptions()}
                  label={dictionary().STATUS}
                  isRequired
                />
              </FormControl>
              <FormControl textAlign='right'>
                <FormLabel>{dictionary().COMMENTS}</FormLabel>
                <Textarea {...register('comment')} placeholder='اكتب الملاحظة' rows={4} resize='none' />
              </FormControl>
            </ModalBody>
            <ModalFooter justifyContent='flex-end'>
              <Button variant='ghost' ml={3} onClick={handleClose}>
                {dictionary().CANCEL}
              </Button>
              <Button type='submit' colorScheme='blue' isLoading={UpdateStatusIsLoading}>
                {dictionary().STATUS_SUBMIT}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <div style={{ height: '50px' }} />
    </>
  )
}

export default NFCRequestHistory
