import { SearchIcon } from '@chakra-ui/icons'
import { Flex, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useDebounce from '../../../helpers/useDebounce'
import { Props } from './OctanePageSearchByType'

export const OctanePageSearchBy: React.FC<Props> = ({ id, placeholder, label }) => {
  const { push } = useHistory()
  const location = useLocation()
  const query = parse(location.search)
  const [searchTerm, setSearchTerm] = useState(query.searchTerm as string)

  const search = useDebounce((value: string) => {
    value ? (query[id] = value) : delete query[id]
    delete query.page
    push({ search: stringify(query) })
  }, 900)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    search(e.target.value)
  }

  return (
    <Flex alignItems='flex-start' flexDirection={'column'} h={'76px'}>
      {label && (
        <Text fontSize='15px' fontWeight='600' color={'#1B202B'} whiteSpace='nowrap' mb={3}>
          {label}
        </Text>
      )}
      <InputGroup flex='1'>
        <InputLeftElement height='100%' pointerEvents='none'>
          <SearchIcon color='gray.700' />
        </InputLeftElement>
        <Input h={'40px'} pr={4} placeholder={placeholder} onChange={onChange} value={searchTerm ?? ''} type='text' size='lg' focusBorderColor='blue.500' />
      </InputGroup>
    </Flex>
  )
}
