import { BoxProps, FlexProps } from '@chakra-ui/react'

export type SpecialFields = Field[]
type Field = {
  // ----------------- key is the route to the value in the object (for download) ex: row.corporate.id ----------------
  key: string
  // ----------- render is the component to be rendered for the cell ex: (row) => <p>{row.corporate.id}</p> -----------
  render: (row: any) => React.ReactNode
  // --------------- header is the component to be rendered for the header (use OctaneHeader prefrebly) ---------------
  // ------------------- type and id props are passed here to be accessed from types as props.value -------------------
  header: JSX.Element
}
type Filter = {
  name: string
  value: string
  default?: boolean
}
export type Tag = {
  name: string
  filter: Filter[]
}

export enum MUTATION_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
export type Props = {
  // ---- the endpoint to fetch data from ------------------------
  mutationEndpoint: string
  // ---- the method to use to fetch data ------------------------
  mutationMethod?: MUTATION_METHOD
  // ---- the fields to display in the table ---------------------
  specialFields?: Field[]
  // ---- the header string to display ---------------------------
  hasHeader?: string
  // ---- the route to redirect to when the header is clicked ----
  headerRouteTo?: string
  // ---- the filter tags to display -----------------------------
  hasTags?: Tag[]
  // ---- to display search input  -------------------------------
  hasSearch?: boolean
  // ---- to display search by date ------------------------------
  hasSearchDate?: boolean
  // ---- Pass filter component, (refer to testAdvancedFilter) ---
  hasAdvancedFilter?: React.ElementType
  // ---- to display pagination ----------------------------------
  hasPagination?: boolean
  // ---- array of search fields to search by --------------------
  hasSearchByKeys?: { searchKey: string; placeholder: string; label?: string }[]
  // ---- to display download button -----------------------------
  hasDownload?: {
    has_csv?: boolean
    has_xlsx?: boolean
  }
  // ---- additional props for the table -------------------------
  tableProps?: {
    // ---------- Custom row styles ------------------------------
    rowStyles?: React.CSSProperties
    // ---------- Custom row onClick -----------------------------
    onRowClick?: (row: any, index: number) => void
    // ---------- Custom cell styles -----------------------------
    cellStyles?: React.CSSProperties
    // ---------- Custom cell ------------------------------------
    onCellClick?: (row: any, key: string, index: number) => void
    // ---------- is row selectable? -----------------------------
    selectable?: boolean
    // ---------- background color forthe first cell -------------
    showFirstCellBackground?: boolean
    // ---------- table container Box props to override ----------
    containerProps?: BoxProps
  }
  // ---- to display drop list filter ----------------------------
  hasDropListFilter?: {
    label: string
    placeholder: string
    searchRoute: string
    singleRoute: string
    formFieldName: string
    valueKey?: string
    displayKey?: string
    isDisabled?: boolean
  }
  // ---------- container props of AdvancedFilter ----------------
  advancedFilterContainerProps?: FlexProps
}
