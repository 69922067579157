import { Icon, Th, Thead, Tr } from '@chakra-ui/react'
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { TableHeadProps, TableHeader } from '../../../interfaces/tableProps'

export const TableHead: React.FC<TableHeadProps> = ({ headers }) => {
  const { search, pathname } = useLocation()
  const { push } = useHistory()
  const query = parse(search, { parseNumbers: true, parseBooleans: true })
  const [isSortDesc, setIsSortDesc] = useState(true)
  const isDuplicatePath = pathname === '/duplicate-transactions'

  const isDuplicateTable = (header: TableHeader) => {
    const isDuplicate = header.key === 'original_transaction_id' && pathname === '/duplicate-transactions' && header.id === 'duplicate_transaction_id'
    return isDuplicate
  }

  const handleDesc = (header: any) => {
    if (header.id === 'version_number') {
      query.version_sort_direction = 'DESC'
    } else {
      query.sort_column = isDuplicatePath ? 'id' : 'fuel-transaction.id'
      query.sort_direction = 'DESC'
    }
    push({ search: stringify(query) })
  }
  const handleAsc = (header: any) => {
    if (header.id === 'version_number') {
      query.version_sort_direction = 'ASC'
    } else {
      query.sort_column = isDuplicatePath ? 'id' : 'fuel-transaction.id'
      query.sort_direction = 'ASC'
    }
    push({ search: stringify(query) })
  }

  useEffect(() => {
    query.sort_direction === 'DESC' || query.version_sort_direction === 'DESC' ? setIsSortDesc(true) : setIsSortDesc(false)
  }, [query.sort_direction, query.version_sort_direction])

  return (
    <Thead position='sticky' top='0' zIndex='9' backgroundColor='#DBD9F2'>
      {headers.map((header) => (
        <Th
          key={header.id ?? header.key}
          isNumeric={header.isRight}
          style={{
            color: undefined,
            cursor: undefined,
            fontWeight: undefined,
            textDecoration: undefined,
            whiteSpace: 'nowrap', // Prevents text from wrapping
            width: 'auto', // Automatically adjusts width based on content
            maxWidth: '100%', // Ensures the cell grows only as much as needed
            paddingRight: '8px',
            paddingLeft: '8px',
            // border: '1px solid #e2e8f0',
            textAlign: 'center',
            height: '50px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', textTransform: 'initial' }}>
            <>{header.name}</>
            {(((header.id === 'id' || isDuplicateTable(header)) &&
              (pathname === '/fuel-transactions' || pathname === '/fuel-transactions-data-entry' || isDuplicateTable(header))) ||
              (header.id === 'version_number' && pathname === '/charging-profiles')) && (
              <div style={{ cursor: 'pointer', marginTop: '6px' }}>
                {isSortDesc ? (
                  <Icon as={FaAngleDoubleDown} boxSize={3} onClick={() => handleAsc(header)} />
                ) : (
                  <Icon as={FaAngleDoubleUp} boxSize={3} onClick={() => handleDesc(header)} />
                )}
              </div>
            )}
          </div>
        </Th>
      ))}
    </Thead>
  )
}
