export const exportFile = (data: any) => {
  try {
    const BOM = '\uFEFF' // UTF-8 BOM
    const csvData = BOM + data

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const downloadUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `${Math.floor(Date.now() / 1000)}_export.csv`)

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)
  } catch (error) {
    console.error('Error downloading file:', error)
    // Handle error, show a message to the user, etc.
  }
}
