import { Button, Flex, Progress, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import fetch from '../../../helpers/fetch'
import { getFilters } from '../../../helpers/getFilters'
import { handleDownloadFile } from '../../../helpers/handleDownloadFile'
import useAlert from '../../../helpers/useAlert'
import { useStore } from '../../../store'
import { useFilterStore } from '../../../stores/filterStore'
import { useNotificationStore } from '../../../stores/pushNotificationStore'
import CsvDownloader from '../../core/Download'
import { DownloadProgress, Props } from './OctaneDownloadType'

const OctaneDownload: FC<Props> = ({ data, headers, restRoute, query, hasDownload }) => {
  const { pathname } = useLocation()
  const state = useFilterStore()
  const filters = getFilters(state)
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const userId = userData && JSON.parse(userData)?.id
  const { initiatePusher, channel, bind, events, terminatePusher, enabled } = useNotificationStore()
  const pathsDoesnotHaveDownload = ['/fuel-transactions', '/charging-profiles', '/confirmed-payments', '/pos-devices']
  const { onError } = useAlert()
  const [loading, setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState<any>(0)

  const onProgress = (progress: DownloadProgress) => {
    if (progress.loaded) {
      setDownloaded(Math.round(progress.loaded / 1024 / 1024).toFixed(2))
    }
  }

  const { mutate } = useMutation((vars: any) => fetch('POST', `fuel-transactions/export`, { data: vars }), {
    onSuccess: (): void => {},
    onError,
  })

  const { mutate: exportCharginProfiles } = useMutation(() => fetch('GET', `charging-profile/export`, { query }), {
    onSuccess: async (data: any) => {
      await handleDownloadFile(import.meta.env.VITE_ANALYTICS_URL, `/utils/download/${data?.file_name}`, data?.file_name, () => {})
    },
    onError,
  })

  useEffect(() => {
    if (channel !== null) {
      bind(events.RUNNING, () => {
        setLoading(true)
      })
      bind(events.FAILED, () => {
        terminatePusher()
        setLoading(false)
      })
      bind(events.SUCCESS, async (data: any) => {
        terminatePusher()
        await handleDownloadFile(import.meta.env.VITE_ANALYTICS_URL, `/utils/download/${data?.exported_file}`, data?.exported_file, onProgress)
        setLoading(false)
        setDownloaded(0)
      })
    }
  }, [channel])

  return (
    <>
      {pathname === '/fuel-transactions' && (
        <Flex align='center' justifyContent='space-between'>
          <Button
            onClick={() => {
              if (!enabled) {
                const userTypeFormated = userType?.toUpperCase()
                initiatePusher(userType?.toUpperCase(), userId)
                //settimeout
                setTimeout(() => {
                  mutate(filters)
                }, 1000)
              } else {
                mutate(filters)
              }
            }}
          >
            {loading ? (
              <div>
                <Progress size='md' isIndeterminate />
                <Text ml={2}>{downloaded + ' Mb'}</Text>
              </div>
            ) : (
              'تحميل CSV'
            )}
          </Button>
        </Flex>
      )}
      {((data && data?.data?.length > 0) || (data && data?.length) || (data && Object.keys(data).length)) && (
        <Flex align='center' justifyContent='space-between'>
          {!pathsDoesnotHaveDownload.includes(pathname) && (
            <CsvDownloader hasDownload={hasDownload} query={query} headers={headers} restRoute={restRoute} isUpload={true} />
          )}

          {pathname === '/charging-profiles' && <Button onClick={() => exportCharginProfiles()}>تحميل CSV</Button>}
        </Flex>
      )}
    </>
  )
}

export default OctaneDownload
