import { GridItem, Grid, Text, Flex, useDisclosure } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import dictionary from '../../../dictionary'
import Card from '../Card'
import Reqiured from '../reqiured'
import PreviewFile from './PreviewFile'
import PreviewContractFile from './PreviewContractFile'

interface Props {
  register: any
  watch: any
  selectedFiles: any[]
  setSelectedFiles: (selectedFile: any) => void
  errors: any
  lastVersionFiles?: any[]
}

const UploadImgInputP = {
  color: '#1E1F48',
  textAlign: 'center',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
}

const UploadContractFiles: FC<Props> = ({ register, watch, selectedFiles, setSelectedFiles, errors, lastVersionFiles }) => {
  const [selectedImages, setSelectedImages] = useState<any[]>([])
  const isNumberError = errors?.contract_files?.type === 'checkFileCount'
  const maxImageSize = 20 * 1024 * 1024
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0)

  const handleOpen = (index: number) => {
    onOpen()
    setSelectedFileIndex(index)
  }

  useEffect(() => {
    lastVersionFiles && lastVersionFiles?.length > 0 && setSelectedImages(lastVersionFiles)
    lastVersionFiles && lastVersionFiles?.length > 0 && setSelectedFiles(lastVersionFiles)
  }, [lastVersionFiles])

  const isUniqueImage = (reader: any, selectedFile: any) => {
    const isUnique = selectedImages.every((image: { content: any; name: any }) => {
      return image.content !== reader.result || image.name !== selectedFile.name
    })
    return isUnique
  }

  const compressAndValidateFileSize = (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      const url = URL.createObjectURL(file)
      img.src = url

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
          reject(new Error('Failed to get canvas context'))
          return
        }
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight

        ctx.drawImage(img, 0, 0)

        canvas.toBlob(
          (compressedBlob) => {
            if (!compressedBlob) {
              reject(new Error('Failed to create compressed blob'))
              return
            }
            if (compressedBlob.size > maxImageSize) {
              reject(new Error(`حجم الملف غير مسموح به الرجاء رفع ملف أقل من أو يساوي 20 ميجا`))
            } else {
              resolve(compressedBlob)
            }
          },
          'image/webp',
          0.5,
        )
        URL.revokeObjectURL(url)
      }

      img.onerror = () => {
        reject(new Error('Failed to load image for compression'))
        URL.revokeObjectURL(url)
      }
    })
  }

  const addNewFile = (e: any) => {
    const selectedFile = e?.target?.files?.[0]
    const input = e.target // Get the file input element

    if (selectedFile && !selectedFile?.type?.startsWith('image/')) {
      if (selectedFile.size <= maxImageSize) {
        const reader = new FileReader()
        reader.onloadend = () => {
          if (isUniqueImage(reader, selectedFile)) {
            setSelectedImages([
              ...selectedImages,
              {
                name: selectedFile.name,
                type: selectedFile.type,
                content: reader.result,
              },
            ])
            setSelectedFiles([...selectedFiles, selectedFile])
          }
        }
        reader.readAsDataURL(selectedFile)
      } else {
        alert(`حجم الملف غير مسموح به الرجاء رفع ملف أقل من أو يساوي 1 ميجا`)
        input.value = ''
      }
    } else {
      compressAndValidateFileSize(selectedFile)
        .then((compressedBlob: any) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            if (isUniqueImage(reader, compressedBlob)) {
              setSelectedImages([
                ...selectedImages,
                {
                  name: compressedBlob.name,
                  type: compressedBlob.type,
                  content: reader.result,
                },
              ])
              setSelectedFiles([...selectedFiles, compressedBlob])
            }
          }
          reader.readAsDataURL(compressedBlob)
        })
        .catch((error) => {
          alert(error.message)
          input.value = ''
        })
    }
  }

  const deleteFile = (fileIndex: number) => {
    setSelectedImages(selectedImages.filter((_, index) => index !== fileIndex))
    setSelectedFiles(selectedFiles.filter((_, index) => index !== fileIndex))
  }

  return (
    <Card p={5}>
      <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            mb={5}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().UPLOAD_CONTRACT} <Reqiured />
          </Text>
        </GridItem>
        <GridItem colSpan={[12]}>
          <Flex direction='row' gap={4} flexWrap='wrap'>
            {selectedImages.length > 0 &&
              selectedImages.map((file, index) => (
                <GridItem colSpan={[9, 1]} onClick={() => file?.startsWith('https:') && handleOpen(index)} key={index}>
                  <PreviewContractFile file={file} deleteFile={() => deleteFile(index)} />
                </GridItem>
              ))}
          </Flex>
        </GridItem>

        <label
          htmlFor='file-input'
          style={{
            cursor: isNumberError ? 'not-allowed' : 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            padding: '0px 5px',
            borderRadius: '10px',
            border: '1px dashed rgba(133, 148, 177, 0.50)',
            width: '150px',
            height: '100px',
            color: isNumberError ? 'red' : '#1E1F48',
          }}
        >
          {isNumberError ? <img src='/assets/UploadPicDisa.svg' alt='upload' /> : <img src='/assets/UploadPic.svg' alt='upload' />}
          <p style={UploadImgInputP as any}>{dictionary().UPLOAD_PIC_FILE}</p>
        </label>
        <input
          id='file-input'
          type='file'
          disabled={isNumberError}
          {...register('contract_files', {
            // required: false,
            required: selectedImages.length === 0 && dictionary().REQUIRED,
            validate: {
              checkFileCount: (files: any) => {
                let addedImages = 0
                let addedPdfs = 0

                for (let i = 0; i < selectedFiles.length; i++) {
                  const file = selectedFiles[i]
                  file?.type?.startsWith('image/') && addedImages++
                  file?.type?.startsWith('application/pdf') && addedPdfs++
                }

                const maxImages = 10 // Change this value to your desired maximum count
                const maxPdfs = 3 // Change this value to your desired maximum count

                // Check if the count exceeds the maximum allowed
                if (addedImages > maxImages) {
                  return dictionary().EXCEEDED_MAXIMUM_IMAGES
                } else if (addedPdfs > maxPdfs) {
                  return dictionary().EXCEEDED_MAXIMUM_FILES
                }
                return true
              },
            },
          })}
          onChange={(e) => {
            addNewFile(e)
          }}
          accept='image/*,.pdf'
          style={{ display: 'none' }}
        />
      </Grid>
      <p style={{ color: '#e53e3e', fontWeight: 600 }}>{errors?.contract_files?.message}</p>
      <PreviewFile
        selectedFileIndex={selectedFileIndex}
        setSelectedFileIndex={setSelectedFileIndex}
        contractFiles={lastVersionFiles}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  )
}

export default UploadContractFiles
