import { Box, Table, BoxProps } from '@chakra-ui/react'
import { FuelTransaction, FuelTransactionResponse } from '../../../types/FuelTransaction'
import OctaneTableBody from './OctaneTableBody'
import OctaneTableHeader from './OctaneTableHeader'

type TableBuilderProps = {
  rowStyles?: React.CSSProperties
  onRowClick?: (row: any, index: number) => void
  cellStyles?: React.CSSProperties
  onCellClick?: (row: any, key: string, index: number) => void
  selectable?: boolean
  showFirstCellBackground?: boolean
  containerProps?: BoxProps
}

export class OctaneTableBuilder {
  private data: FuelTransaction[]

  private total: number

  private columns: string[] | React.ReactNode[]

  private columnsObj: string[] | React.ReactNode[]

  private specialFields: any[]

  private props: TableBuilderProps

  constructor(
    data: FuelTransactionResponse,
    headers: {
      headersList: string[] | React.ReactNode[]
      headers: string[] | React.ReactNode[]
    },
    props: TableBuilderProps = {},
  ) {
    this.data = data?.data || []
    this.total = data?.total || 0
    this.columns = headers.headers
    this.columnsObj = headers.headersList
    this.specialFields = []
    this.props = props
    return this
  }

  addColumn(header: React.ReactNode): OctaneTableBuilder {
    const newBuilder = new OctaneTableBuilder({ data: this.data, total: this.total }, { headers: this.columns, headersList: this.columnsObj })
    newBuilder.columns = [...this.columns, header]
    return newBuilder
  }

  addSpecialFields(key: string, headerFunction: (row: any) => React.ReactNode): OctaneTableBuilder {
    this.specialFields.push({ key, headerFunction })
    return this
  }

  build(): React.ReactNode {
    const defaultRaisedProps = {
      borderRadius: 'lg',
      boxShadow: '1px',
      borderWidth: '1px',
      bg: 'white',
      maxH: '75vh',
      overflowY: 'auto' as const,
    }

    const containerProps = {
      ...defaultRaisedProps,
      ...this.props.containerProps,
    }

    return (
      <Box {...containerProps}>
        <Table variant='striped' id='transactions-table'>
          <OctaneTableHeader headers={this.columns} />
          <OctaneTableBody headersList={this.columnsObj} data={this.data} specialFields={this.specialFields} {...this.props} />
        </Table>
      </Box>
    )
  }
}
