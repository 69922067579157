import { Checkbox, FormControl, FormErrorMessage, Input, InputGroup, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import React, { FC, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import { Detail } from './VersionDetailsData'

type Props = {
  detail: Detail
  control: any
  errors: any
  disabled?: boolean
  getValues?: any
  setValue?: any
}

const FormEdit: FC<Props> = ({ detail, control, errors, getValues, setValue, disabled = false }) => {
  const pathname = window.location.pathname
  const isInputDisabled = detail.disabled || false
  const inputName = detail.register.name

  const handleInputType = (type: string) => {
    switch (type) {
      case 'radio':
        const defaultOption = detail?.options?.find((opt) => opt.defaultChecked)?.value || detail?.options?.[0]?.value || ''
        useEffect(() => {
          //------------ set value if defaultOption, setValue, getValues are available and value is not already set ------------
          if (defaultOption && setValue && getValues && !getValues(inputName)) {
            setValue(inputName, defaultOption)
          }
          //--------------------------------------------------------------------------------------------------------------------
        }, [])

        return (
          <FormControl id='offline_verification' w='90%' isInvalid={!!errors[inputName]} mt={2}>
            <Controller
              name={inputName}
              control={control}
              defaultValue={defaultOption}
              render={({ field: { onChange, value } }) => (
                <RadioGroup onChange={onChange} value={value || defaultOption} isDisabled={disabled || isInputDisabled}>
                  <Stack direction='row' gap={4}>
                    {detail?.options &&
                      detail?.options.map((item, index: number) => (
                        <Radio
                          style={{
                            fontFamily: 'Noto Kufi Arabic',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            textAlign: 'left',
                          }}
                          key={index}
                          value={item.value}
                          color='purple'
                          alignItems={'center'}
                          disabled={disabled}
                          data-test={detail.name}
                        >
                          {item.label}
                        </Radio>
                      ))}
                  </Stack>
                </RadioGroup>
              )}
              rules={{
                required: detail.required ? dictionary().REQUIRED : false,
              }}
            />
            <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
          </FormControl>
        )
      case 'checkbox':
        return (
          <FormControl id='offline_verification' w='90%' isInvalid={!!errors[inputName]} mt={2}>
            <Controller
              name={inputName}
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={onChange}
                  data-test={detail.name}
                  disabled={disabled || detail?.register?.name === 'auto_refund'}
                  color='purple'
                  // defaultChecked={true}
                  isChecked={getValues && getValues(detail?.register?.name)}
                  textTransform='capitalize'
                >
                  {detail?.label}
                </Checkbox>
              )}
              rules={{
                required: false,
              }}
            />
            <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
          </FormControl>
        )
      case 'select':
        return (
          <FormControl id={detail.name} w='90%' isInvalid={!!errors[inputName]}>
            <Select dir='rtl' textAlign='left' {...detail?.register} disabled={pathname === '/charging-profiles/new' ? false : disabled || isInputDisabled}>
              {detail?.options &&
                detail?.options.map((item: any, index: number) => (
                  <option
                    key={index}
                    value={item.value}
                    style={{
                      fontFamily: 'Noto Kufi Arabic',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      textAlign: 'left',
                      direction: 'rtl',
                    }}
                  >
                    {item.label}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
          </FormControl>
        )
      case 'date':
        return (
          <FormControl id={detail.name} w='90%' display='inline-block' isInvalid={!!errors[inputName]}>
            <InputGroup>
              <Input
                type={detail.type}
                {...detail.register}
                disabled={disabled || isInputDisabled}
                onKeyDown={(e) => e.preventDefault()}
                onKeyPress={(e) => e.preventDefault()}
                onKeyUp={(e) => e.preventDefault()}
              />
            </InputGroup>
            <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
          </FormControl>
        )
      default:
        return (
          <FormControl id={detail.name} w='90%' display='inline-block' isInvalid={!!errors[inputName]}>
            <InputGroup>
              <Input
                data-test={detail?.register?.name === 'fraud_detection_fees' && getValues('bundle_type') === 1 ? 'fraud_detection_fees' : detail?.name}
                style={{
                  fontFamily: 'Noto Kufi Arabic',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'right',
                }}
                type={detail.type}
                {...detail.register}
                disabled={disabled || (detail?.register?.name === 'fraud_detection_fees' && getValues('bundle_type') === 1)}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    event.preventDefault() // Stop the default action of the event
                  }
                }}
              />
            </InputGroup>
            <FormErrorMessage>{errors[inputName]?.message}</FormErrorMessage>
          </FormControl>
        )
    }
  }

  return handleInputType(detail.type)
}

export default FormEdit
