import { useEffect, useRef, useState } from 'react'

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'

import dictionary from '../../dictionary'

interface Props {
  data?: any
  header?: any
  pump?: boolean
  srcValue?: string | null
}
const UploadImgWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginX: 'auto',
  marginTop: '20px',
  gap: '10px',
}

const UploadImgStyle = {
  borderRadius: '10px',
  border: '1px dashed rgba(133, 148, 177, 0.50)',
  width: '368px',
  height: '288px',
  padding: '5px',
}
const PreviewImg = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
  borderRadius: '10px',
  border: '1px dashed rgba(133, 148, 177, 0.50)',
  width: '150px',
  height: '100px',
  padding: '5px',
  cursor: 'pointer',
}

const CarServicesModal: React.FC<Props> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const [selectedImages, setSelectedImages] = useState<any>([])
  const [selectedImage, setSelectedImage] = useState<any>(selectedImages[0])

  useEffect(() => {
    const temp = []
    if (data?.carWashDetails?.car_image_url) temp.push(data?.carWashDetails?.car_image_url)
    if (data?.oilChangeDetails?.odometer_image_url) temp.push(data?.oilChangeDetails?.odometer_image_url)
    if (data?.invoice_image_url) temp.push(data?.invoice_image_url)
    temp.length > 0 && setSelectedImages(temp)
  }, [])

  useEffect(() => {
    selectedImages[0] && setSelectedImage(selectedImages[0])
  }, [selectedImages])

  return (
    <>
      <img data-test='car_services_images' src='/assets/imageIcon.svg' onClick={onOpen} />
      <Modal size={'xl'} initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().IMAGES} </ModalHeader>
          <ModalBody pb={6}>
            <Box style={UploadImgWrapper}>
              <Box style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                {selectedImages?.length > 0 &&
                  selectedImages.map((image: any, index: number) => {
                    return (
                      image && (
                        <img
                          key={index}
                          data-test={`car_services_image_${index + 1}`}
                          src={image}
                          alt='Selected Preview'
                          style={PreviewImg}
                          onClick={() => setSelectedImage(image)}
                        />
                      )
                    )
                  })}
              </Box>
              {selectedImage ? (
                <Box style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                  <img src={selectedImage} alt='Selected Preview' style={UploadImgStyle} />
                  <Box style={{ direction: 'rtl', fontSize: '20px', marginTop: '10px' }}>
                    <span>{dictionary().AMOUNT}: </span>
                    <b>{data?.service_amount} </b>
                  </Box>
                </Box>
              ) : (
                dictionary().NO_IMG
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CarServicesModal
