import { Box, Spinner, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../dictionary'
import { HistoryRecord, TableHeader } from './NFCRequestHistoryType'
import NFCStatusCell from './NFCStatusCell'

type Props = {
  GetHistoryData: HistoryRecord[]
  isLoading: boolean
}
const truncateText = (text: string, maxLength: number) => {
  if (!text) return '-'
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
}

const tableHeaders: TableHeader[] = [
  {
    key: 'created_at',
    displayName: dictionary().STATUS_DATE,
    render: (value) => new Date(value).toLocaleDateString(),
  },
  {
    key: 'created_at',
    displayName: dictionary().STATUS_TIME,
    render: (value) => <div style={{ minWidth: '80px' }}>{new Date(value).toLocaleTimeString()} </div>,
  },
  {
    key: 'status',
    displayName: dictionary().STATUS,
    render: (value) => (
      <div style={{ minWidth: '120px' }}>
        <NFCStatusCell status={value} />{' '}
      </div>
    ),
  },
  {
    key: 'admin.username',
    displayName: dictionary().CHANGED_BY,
    render: (value) =>
      value ? (
        <Tooltip label={value} placement='top' bg='white' color='black' boxShadow='0px 4px 6px rgba(0, 0, 0, 0.1)' p={2} borderRadius='md'>
          <Text>{truncateText(value, 9)}</Text>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    key: 'comment',
    displayName: dictionary().COMMENTS,
    render: (value) =>
      value ? (
        <Tooltip label={value} placement='top' bg='white' color='black' boxShadow='0px 4px 6px rgba(0, 0, 0, 0.1)' borderRadius='md'>
          <Text>{truncateText(value, 5)}</Text>
        </Tooltip>
      ) : (
        '-'
      ),
  },
]

const NFCHistoryTable: FC<Props> = ({ GetHistoryData, isLoading }) => {
  return (
    <Box overflowX='auto' width='100%'>
      <Table variant='simple' minWidth='500px'>
        <Thead style={{ backgroundColor: '#DBD9F2' }}>
          <Tr>
            {tableHeaders.map((header) => (
              <Th key={header.key}>{header.displayName}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={5} textAlign='center'>
                <Spinner />
              </Td>
            </Tr>
          ) : GetHistoryData?.length > 0 ? (
            GetHistoryData?.map((record: HistoryRecord) => (
              <Tr key={record.id}>
                {tableHeaders.map((header) => (
                  <Td key={header.key}>
                    {header.render
                      ? header.render(header.key.includes('.') ? record.admin.username : record[header.key as keyof HistoryRecord])
                      : header.key.includes('.')
                      ? record.admin.username
                      : record[header.key as keyof HistoryRecord]}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={5} textAlign='center'>
                {dictionary().NO_HISTORY}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}

export default NFCHistoryTable
