import { Button, FormControl, FormLabel, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import downArrow from '../../assets/arrow-down.png'
import NFCStatusCell from './NFCStatusCell'

type CustomStatusSelectProps = {
  value: string
  onChange: (value: string) => void
  options: Array<{ value: string; label: string }>
  label: string
  isRequired?: boolean
}

const CustomStatusSelect = ({ value, onChange, options, label, isRequired }: CustomStatusSelectProps) => {
  return (
    <FormControl isRequired={isRequired} textAlign='right'>
      <FormLabel>{label}</FormLabel>
      <Menu matchWidth>
        <MenuButton
          as={Button}
          rightIcon={<img src={downArrow} width={20} height={20} />}
          width='100%'
          textAlign='right'
          variant='unstyled'
          border='1px solid'
          borderColor='gray.200'
          borderRadius='md'
          paddingLeft={4}
          paddingRight={4}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          {value ? <NFCStatusCell status={value} hasBackgroundColor={false} /> : 'اختر الحالة'}
        </MenuButton>
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option.value} onClick={() => onChange(option.value)} width='100%'>
              <NFCStatusCell status={option.value} hasBackgroundColor={false} />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </FormControl>
  )
}

export default CustomStatusSelect
