import React, { useState, useCallback, useEffect } from 'react'
import { pathOr } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Grids from '../../components/core/Grids'
import fetch from '../../helpers/fetch'
import { useDetailsStore } from '../../stores/detailsStore'

interface ViewProps {
  restRoute: string
  title: string
  cashingKey: string
  dataLocation: string
  header: { key: string; name: string }[]
  actions?: { action?: string; title?: string; apiRoute?: string; redirectRoute?: string }[]
  userType?: string
  query?: { [key: string]: number | string | boolean | Array<number> | Array<string> | Array<boolean> }
  table?: { render: React.FC<any>; dataPath: string }
  customComponent?: React.FC<any>
}
export interface data {
  id: number
  data: any
}

const ViewOne: React.FC<ViewProps> = ({ actions, header, restRoute, title, dataLocation, cashingKey, userType, query = {}, table, customComponent }) => {
  const { push, replace } = useHistory()
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState({})
  const { setDetails } = useDetailsStore()
  console.log('🚀 ~ query:', query)
  const { isLoading, mutate, error, isError } = useMutation(() => fetch('GET', `${restRoute}/${id}`, { query }), {
    onSuccess: (data: data) => {
      setData(data)
      setDetails(data)
    },
    onError: () => {
      if (!data || (error as any)?.data?.message === 'Vehicle is currently not active') {
        replace(`/${pathname.split('/')[1]}`)
      }
    },
  })
  const redirect = (destination: any) => {
    push(`${location?.pathname}/${destination}`)
  }
  const [isBalanseChanged, setIsBalanseChanged] = useState(false)
  useEffect(() => {
    mutate()
  }, [isBalanseChanged])

  // useEffect(() => {
  //   !isError && mutate()
  // }, [])

  const handleIsBalanseChanged = useCallback(() => {
    setIsBalanseChanged(!isBalanseChanged)
  }, [isBalanseChanged])

  return (
    <Grids
      header={header}
      cashingKey={cashingKey}
      isLoading={isLoading}
      redirect={redirect}
      data={pathOr(data, [dataLocation], data)}
      title={title}
      actions={actions}
      userType={userType}
      handleIsBalanseChanged={handleIsBalanseChanged}
      table={table}
      customComponent={customComponent}
    />
  )
}

export default ViewOne
