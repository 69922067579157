import { useMemo } from 'react'
import moment from 'moment'
import dictionary from '../../dictionary'
import OctaneHeader from '../../components/core/OctaneTable/Header'
import RowId from '../../components/FuelTransactionTable/RowId'
import CarServicesModal from '../../components/core/CarServicesModal'
import { SpecialFields } from '../../components/OctanePage/OctanePage/OctanePageType'

export const useCarServicesConfig = () => {
  const tags = [
    { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
    { name: dictionary().CONFIRMED, filter: [{ name: 'service_status', value: 'CONFIRMED', default: true }] },
    { name: dictionary().PENDING, filter: [{ name: 'service_status', value: 'PENDING' }] },
  ]

  const searchFields: { searchKey: string; placeholder: string; label?: string }[] = [
    { searchKey: 'service_transaction_id', placeholder: dictionary().SEARCH_BY_TRANSACTION_ID, label: dictionary().SEARCH_BY_TRANSACTION_ID },
  ]

  const specialFields: SpecialFields = useMemo(
    () => [
      {
        key: 'id',
        render: (row) => <p style={{ fontWeight: 600 }}>{row?.id}</p>,
        header: <RowId />,
      },
      {
        key: 'created_date',
        render: (row) => <p>{moment.utc(new Date(row?.created_at as any)).format('DD/MM/YYYY')}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'created_date'} type='text' value={dictionary().DATE} />,
      },
      {
        key: 'created_time',
        render: (row) => <p>{moment.utc(row?.created_at as any).format('hh:mm A')}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'created_time'} type='text' value={dictionary().TIME} />,
      },
      {
        key: 'service_type',
        render: (row) => <p>{['OIL_CHANGE', 'CAR_WASH'].includes(row?.service_type) ? dictionary()[row?.service_type as keyof typeof dictionary] : '-'}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'service_type'} type='text' value={dictionary().SERVICE_TYPE} />,
      },
      {
        key: 'status',
        render: (row) => <p>{dictionary()[row?.status as keyof typeof dictionary]}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'status'} type='text' value={dictionary().STATUS} />,
      },
      {
        key: 'corporate_name',
        render: (row) => <p>{row?.corporate?.name}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'corporate_name'} type='text' value={dictionary().CORPORATES_NAME} />,
      },
      {
        key: 'station_name',
        render: (row) => <p>{row?.station?.name?.ar}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'station_name'} type='text' value={dictionary().STATION_NAME} />,
      },
      {
        key: 'driver_name',
        render: (row) => <p>{row?.driver?.name}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'driver_name'} type='text' value={dictionary().DRIVER_NAME} />,
      },
      {
        key: 'driver_code',
        render: (row) => <p>{row?.driver?.code}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'driver_code'} type='text' value={dictionary().DRIVER_CODE} />,
      },
      {
        key: 'vehicle_id',
        render: (row) => <p>{row?.vehicle?.id}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'vehicle_id'} type='text' value={dictionary().VEHICLE_ID} />,
      },
      {
        key: 'vehicle_code',
        render: (row) => <p>{row?.vehicle?.code}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'vehicle_code'} type='text' value={dictionary().VEHICLE_CODE} />,
      },
      {
        key: 'vehicle_number_plate',
        render: (row) => <p>{row?.vehicle?.number_plate}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'vehicle_number_plate'} type='text' value={dictionary().NUMBER_PLATE} />,
      },
      {
        key: 'pos_serial_number',
        render: (row) => <p>{row?.pos?.serial_number}</p>,
        header: <OctaneHeader style={{ textAlign: 'center' }} id={'pos_serial_number'} type='text' value={dictionary().POS_SERIAL_NUMBER} />,
      },
      {
        key: 'service_amount',
        render: (row) => <p>{row?.service_amount}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'service_amount'} type='text' value={dictionary().AMOUNT} />,
      },
      {
        key: 'fees',
        render: (row) => <p>{row?.fees}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'fees'} type='text' value={dictionary().AMOUNT_FEES} />,
      },
      {
        key: 'total_amount',
        render: (row) => <p>{row?.total_amount}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'total_amount'} type='text' value={dictionary().AMOUNT_TOTAL} />,
      },
      {
        key: 'images',
        render: (row) => (
          <div style={{ cursor: 'pointer' }}>
            <CarServicesModal data={row} />
          </div>
        ),
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'images'} type='text' value={dictionary().IMAGES} />,
      },
    ],
    [],
  )

  return {
    tags,
    searchFields,
    specialFields,
  }
}
