import React from 'react'
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { DurationRadioOptions } from '../../../components/core/ChargingProfileDetails/AddProfileType'

export const ExtendDialog = ({
  extendDialogOpen,
  onExtendDialogClose,
  extendPeriod,
  setValue,
  control,
  errors,
  register,
  watch,
  setRejectReason,
}: {
  extendDialogOpen: boolean
  onExtendDialogClose: () => void
  extendPeriod: () => void
  setValue: any
  control: any
  errors: any
  register: any
  watch: any
  setRejectReason: (value: string) => void
}) => {
  const cancelRef = React.useRef<any>()
  return (
    <AlertDialog
      isOpen={extendDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setValue('type', '')
        setValue('durationType', '')
        setValue('trial_duration_in_days', '')
        onExtendDialogClose()
      }}
      isCentered
    >
      <AlertDialogOverlay bg='blackAlpha.300' backdropFilter='blur(10px)'>
        {/* @ts-ignore */}
        <AlertDialogContent dir='rtl'>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            مد الفترة
          </AlertDialogHeader>

          <AlertDialogBody>
            <>
              <DurationRadioOptions control={control} errors={errors} register={register} watch={watch} setValue={setValue} />
            </>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                setRejectReason('')
                onExtendDialogClose()
              }}
            >
              إلغاء
            </Button>
            <Button
              colorScheme='cyan'
              onClick={() => {
                extendPeriod()
                onExtendDialogClose()
              }}
              mr={3}
            >
              مد الفترة
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
