import { Flex, Tag } from '@chakra-ui/react'
import { parse, ParsedQuery, stringify } from 'query-string'
import { flatten, isNil, pickBy, pluck } from 'ramda'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFilterStore } from '../../stores/filterStore'

export interface IFilter {
  name: string
  value?: string | number | boolean
  default?: boolean
}

export interface ITag {
  name: string
  filter: IFilter[]
}

interface ViewTagProps {
  filter: IFilter[]
  name: string
  tags: ITag[]
  replaceRoute?: boolean
}

interface ViewTagsProps {
  tags: ITag[]
  replaceRoute?: boolean
}

const removeOldFilterFromQuery = (query: ParsedQuery<string | boolean | number>, filter: IFilter[], tags: ITag[]) => {
  const filterNames = new Set<string>()
  filter.forEach((f) => filterNames.add(f.name))

  const keysToRemove = flatten(tags.map((tag) => pluck('name', tag.filter))).filter((n) => !filterNames.has(n))
  const notToBeRemoved = (val: unknown, key: string | number) => !(keysToRemove.includes(`${key}`) || isNil(val) || !key)

  const newQuery = pickBy(notToBeRemoved, query) as ParsedQuery<string | boolean>
  return newQuery
}

const addNewFilterToQuery = (query: ParsedQuery<string | boolean | number>, filter: IFilter[]) => {
  const newQuery = { ...query }
  filter.forEach((f) => {
    if (f.name) {
      newQuery[f.name] = typeof f.value === 'boolean' ? f.value : `${f.value}`
    }
  })

  return newQuery as ParsedQuery<string | boolean | number>
}

const ViewTag: React.FC<ViewTagProps> = ({ name, filter, tags, replaceRoute }) => {
  const location = useLocation()
  const { setStatus, status } = useFilterStore()
  const history = useHistory()

  const query = parse(location.search, { parseBooleans: true, parseNumbers: true })

  const isFilterActive = (filterArray: IFilter[]) => filterArray.every((f) => query[f.name] === (typeof f.value == 'boolean' ? f.value : f.value || undefined))
  const isSelfActive = isFilterActive(filter)
  const othersActive = tags.filter((tag) => tag.name !== name).some((tag) => isFilterActive(tag.filter))
  const isAll = filter.length === 1 && typeof filter[0].value !== 'boolean' ? !filter[0].value : false
  let isActive: boolean
  if (location.pathname === '/fuel-transactions') {
    isActive = (status === 'CONFIRMED' && !isAll) || (status === null && isAll) ? true : false
  } else {
    isActive = isAll ? isSelfActive && !othersActive : isSelfActive
  }

  const handleClick = () => {
    if (isActive) return
    const queryWithOldFieldsRemoved = removeOldFilterFromQuery(query, filter, tags)
    const queryWithNewFieldsAdded = addNewFilterToQuery(queryWithOldFieldsRemoved, filter)
    const { status } = queryWithNewFieldsAdded
    setStatus((status as string) ?? null)
    if (replaceRoute) history.replace({ search: stringify(queryWithNewFieldsAdded) })
    else history.push({ search: stringify(queryWithNewFieldsAdded) })
  }

  return (
    <Tag
      sx={{
        zIndex: 322,
        whiteSpace: 'nowrap',
        height: '40px',
        minWidth: '58px',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '500',
        fontsize: '13px',
        paddingLeft: '13px',
        paddingRight: '13px',
      }}
      cursor='pointer'
      onClick={handleClick}
      bg={isActive ? 'blue.500' : '#CDD5DF'}
      color={isActive ? 'white' : undefined}
    >
      {name}
    </Tag>
  )
}

const ViewTags: React.FC<ViewTagsProps> = ({ tags, replaceRoute }) => {
  return (
    <Flex justifyContent={'space-between'} gap={2} alignItems={'center'}>
      {tags.map((tag) => (
        <ViewTag key={tag.name} name={tag.name} filter={tag.filter} tags={tags} replaceRoute={replaceRoute} />
      ))}
    </Flex>
  )
}

export default ViewTags
