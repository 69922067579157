// ----------- Refer to OctanePage.md for documentation ----------- //
// ----------- Refer to OctanePageType.ts for types --------------- //

import { Container, Flex } from '@chakra-ui/react'
import { format } from 'date-fns'
import { stringify } from 'query-string'
import { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import fetch from '../../../helpers/fetch'
import { flatObject } from '../../../helpers/flatObject'
import useAlert from '../../../helpers/useAlert'
import useQueryParams from '../../../hooks/useQueryParams '
import { useFilterStore } from '../../../stores/filterStore'
import FilterDropList from '../../core/FilterDropList'
import Header from '../../core/Header'
import Pagination from '../../core/Pagination'
import { Search } from '../../core/Search'
import SearchDate from '../../core/SearchDate'
import ViewTags from '../../core/ViewTags'
import OctaneDownload from '../OctaneDownload/OctaneDownload'
import { OctanePageSearchBy } from '../OctanePageSearchBy/OctanePageSearchBy'
import OctaneTable from '../OctaneTable/OctaneTable'
import { MUTATION_METHOD, Props } from './OctanePageType'

const OctanePage: FC<Props> = ({
  mutationEndpoint,
  mutationMethod = MUTATION_METHOD.GET,
  specialFields = [],
  hasHeader,
  headerRouteTo = '',
  hasTags,
  hasSearch = true,
  hasSearchDate = true,
  hasAdvancedFilter: HasAdvancedFilter,
  advancedFilterContainerProps,
  hasPagination = true,
  hasSearchByKeys = [],
  hasDownload = { has_csv: true, has_xlsx: true },
  hasDropListFilter,
  tableProps,
}) => {
  const { search } = useLocation()
  const history = useHistory()
  const [isInitialized, setIsInitialized] = useState(false)
  const searchParams = new URLSearchParams(search)
  const searchParamValues = hasSearchByKeys?.map((field) => searchParams.get(`${field.searchKey}`))
  const { query } = useQueryParams()

  const state = useFilterStore()
  const { from, page, sort_column, sort_direction, to } = state

  const { onError } = useAlert()

  const convertToHeaderObject = (array: any): { [key: string]: JSX.Element } => {
    return array.reduce((acc: any, item: any) => {
      acc[item.key] = item.header
      return acc
    }, {} as { [key: string]: JSX.Element })
  }

  const headers = convertToHeaderObject(specialFields)

  const toQueryString = (filters: any) =>
    Object.keys(filters)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key] || ''))
      .join('&')

  const { isLoading, mutate, data } = useMutation(
    (filters: any) => {
      const queryString = toQueryString(filters)
      return fetch(`${mutationMethod}`, `${mutationEndpoint}?${queryString}`)
    },
    {
      onSuccess: (): void => {},
      onError,
    },
  )

  const getFilterObject = () => {
    const fromDate = format(new Date(from), 'yyyy-MM-dd')
    const toDate = format(new Date(to), 'yyyy-MM-dd')
    const filterObj: any = { sort_direction, page, from: fromDate, to: toDate, ...query }

    hasSearchByKeys?.forEach((field, index) => {
      const value = searchParamValues[index]
      if (value) {
        filterObj[field.searchKey] = value
      } else {
        delete filterObj[field.searchKey]
      }
    })

    return filterObj
  }

  // --------------------- Set default filter if it exists ---------------------
  useEffect(() => {
    if (!isInitialized) {
      if (!search && hasTags) {
        const defaultTag = hasTags.find((tag) => tag.filter.some((f) => f.default === true))
        if (defaultTag) {
          history.replace({
            search: stringify(Object.fromEntries(defaultTag.filter.map((f) => [f.name, f.value]))),
          })
        }
      }
      setIsInitialized(true)
    }
  }, [hasTags, search, history])
  // ---------------------------------------------------------------------------

  // -------------- fetch data after default filter is set ---------------------
  useEffect(() => {
    if (isInitialized) {
      const filterObj = getFilterObject()
      mutate(filterObj)
    }
  }, [isInitialized, from, to, sort_column, sort_direction, page, query, mutate, JSON.stringify(searchParamValues)])
  // ---------------------------------------------------------------------------

  const extractKeyValuePairs = () => {
    return Object.keys(headers).map((key) => ({
      id: headers?.[key]?.props?.id,
      key: key,
      name: headers?.[key]?.props?.value,
      type: headers?.[key]?.props?.type,
    }))
  }

  const getHeaders = (): { headersList: any; headers: any } => {
    return { headersList: flatObject(headers, 'keys'), headers: flatObject(headers, 'values') }
  }

  const handleDropListSelect = (value: any) => {
    const filterObj = getFilterObject()
    if (value) {
      filterObj[hasDropListFilter?.formFieldName || ''] = value.id
    }
    mutate(filterObj)
  }

  if (!isInitialized) return null

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={5}>
      <Flex flexDir='column' sx={{ '& > *:not(:last-child)': { marginBottom: '15px' } }}>
        {hasHeader && <Header title={hasHeader} to={headerRouteTo} />}
        <Flex justifyContent='flex-start' alignItems='center' flexWrap='wrap' mt={4} width='100%' flexDirection={'column'}>
          <Flex justifyContent='flex-start' alignItems='center' flexWrap='wrap' width='100%' flexDirection={'row'}>
            {hasSearchDate && (
              <Flex ml={9} display='flex' alignItems='center' mb={7}>
                <SearchDate
                  labelProps={{
                    mb: 3,
                  }}
                />
              </Flex>
            )}

            {hasSearchByKeys &&
              hasSearchByKeys?.map((item) => {
                return (
                  <Flex ml={9} key={item.searchKey} width='183px' mb={7}>
                    <OctanePageSearchBy id={item.searchKey} placeholder={item.placeholder} label={item.label} />
                  </Flex>
                )
              })}

            {hasDropListFilter && (
              <Flex ml={9} mb={7}>
                <FilterDropList {...hasDropListFilter} onSelect={handleDropListSelect} shouldUseFilterStore={false} />
              </Flex>
            )}

            {HasAdvancedFilter && (
              <Flex mt={8} alignItems='center' h={'40px'} {...advancedFilterContainerProps} mb={7}>
                <HasAdvancedFilter />
              </Flex>
            )}
          </Flex>

          <Flex justifyContent='flex-start' alignItems='center' flexWrap='wrap' width='100%' flexDirection={'row'}>
            {hasSearch && (
              <Flex ml={9} w={'400px'} mb={2}>
                <Search />
              </Flex>
            )}

            {hasTags && (
              <Flex alignItems='center' h={'40px'} mb={2}>
                <ViewTags tags={hasTags} />
              </Flex>
            )}

            <Flex alignSelf={'flex-end'} mr={'auto'} mb={2}>
              {hasDownload && (
                <OctaneDownload
                  hasDownload={hasDownload}
                  data={data}
                  headers={extractKeyValuePairs()}
                  query={toQueryString(getFilterObject())}
                  restRoute={mutationEndpoint}
                />
              )}
              {hasPagination && <Pagination totalCount={data?.total || 0} pageSize={data?.page_size || 30} />}
            </Flex>
          </Flex>
        </Flex>
        <OctaneTable data={data} loading={isLoading} getHeaders={getHeaders} specialFields={specialFields} tableProps={tableProps} />
      </Flex>
    </Container>
  )
}

export default OctanePage
