import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import OctaneHeader from '../../components/core/OctaneTable/Header'
import OctanePage from '../../components/OctanePage/OctanePage/OctanePage'
import { MUTATION_METHOD, SpecialFields } from '../../components/OctanePage/OctanePage/OctanePageType'
import dictionary from '../../dictionary'
import NFCStatusCell from './NFCStatusCell'

const tags = [
  { name: dictionary().ALL, filter: [{ name: '', value: '', default: true }] },
  { name: dictionary().NFC_STATUS_DONE, filter: [{ name: 'status', value: 'DONE' }] },
  { name: dictionary().NFC_STATUS_IN_PROGRESS, filter: [{ name: 'status', value: 'IN_PROGRESS' }] },
  { name: dictionary().NFC_STATUS_AWAITING_REVIEW, filter: [{ name: 'status', value: 'AWAITING_REVIEW' }] },
  { name: dictionary().NFC_STATUS_REJECTED, filter: [{ name: 'status', value: 'REJECTED' }] },
  { name: dictionary().NFC_STATUS_READY_FOR_DELIVERY, filter: [{ name: 'status', value: 'READY_FOR_DELIVERY' }] },
  { name: dictionary().NFC_STATUS_DELIVERED, filter: [{ name: 'status', value: 'DELIVERED' }] },
]

const nfcReplacmentsSearchFields: { searchKey: string; placeholder: string; label?: string }[] = [
  { searchKey: 'corporateName', placeholder: dictionary().CORPORATE_NAME, label: dictionary().CORPORATE_NAME },
  { searchKey: 'corporateGroupName', placeholder: dictionary().CORPORATES_GROUP, label: dictionary().CORPORATES_GROUP },
]

const NFCRequests = () => {
  const { push } = useHistory()
  const specialFields: SpecialFields = useMemo(() => {
    return [
      {
        key: 'id',
        render: (row) => <p>{row?.id}</p>,
        header: <OctaneHeader id={'id'} type='text' value={dictionary().ID} />,
      },
      {
        key: 'requested_by',
        render: (row) => <p style={{ textAlign: 'start' }}>{row?.requested_by ?? '-'}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'requested_by'} type='text' value={dictionary().REQUESTED_BY} />,
      },
      {
        key: 'corporateName',
        render: (row) => <p style={{ textAlign: 'start' }}>{row?.corporateName ?? '-'}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'corporate_staff'} type='text' value={dictionary().CORPORATE} />,
      },
      {
        key: 'corporateGroupName.name',
        render: (row) => <p style={{ textAlign: 'start' }}>{row?.corporateGroupName ?? '-'}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'corporateGroupName'} type='text' value={dictionary().CORPORATES_GROUP} />,
      },
      {
        key: 'status',
        render: (row) => <NFCStatusCell status={row?.status} />,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'status'} type='text' value={dictionary().STATUS} />,
      },
      {
        key: 'created_at',
        render: (row) => <p style={{ textAlign: 'start' }}>{moment.utc(new Date(row?.created_at)).format('DD/MM/YYYY')}</p>,
        header: <OctaneHeader style={{ textAlign: 'start' }} id={'created_at'} type='text' value={dictionary().CREATED_AT} />,
      },
    ]
  }, [])

  return (
    <OctanePage
      mutationEndpoint='/nfc-replacement-requests'
      mutationMethod={MUTATION_METHOD.GET}
      specialFields={specialFields}
      hasHeader={dictionary().NFC_REQUESTS}
      headerRouteTo=''
      hasTags={tags}
      hasSearchDate={false}
      hasPagination={true}
      hasSearch={true}
      hasSearchByKeys={nfcReplacmentsSearchFields}
      hasDownload={{ has_csv: false, has_xlsx: false }}
      tableProps={{
        rowStyles: { cursor: 'pointer' },
        onRowClick: (row, index) => {
          push(`/nfc-requests/${row?.id}`)
        },
        cellStyles: {},
        onCellClick: (row, key, index) => {},
        selectable: false,
        showFirstCellBackground: false,
        containerProps: {
          borderRadius: 'none',
          maxH: '70vh',
        },
      }}
    />
  )
}

export default NFCRequests
