import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'
import NFCDetailsVehicleTable from './NFCDetailsVehicleTable'
import NFCRequestHistory from './NFCRequestHistory'

const header = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'created_at',
    key: 'created_at',
    name: dictionary().CREATED_AT,
    type: 'date',
  },
  {
    id: 'corporate_name',
    key: 'corporate_name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'corporate_group_name',
    key: 'corporate_group_name',
    name: dictionary().CORPORATES_GROUP,
    type: 'text',
  },
  {
    id: 'requested_by',
    key: 'requested_by',
    name: dictionary().REQUEST_BY,
    type: 'text',
  },
  {
    id: 'delivery_option',
    key: 'delivery_option',
    name: dictionary().DELIVERY_OPTION,
    type: 'text',
    section: 'delivery',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
    section: 'delivery',
  },

  {
    id: 'mobile_number',
    key: 'mobile_number',
    name: dictionary().PHONE_NUMBER,
    type: 'text',
    section: 'delivery',
  },
  {
    id: 'name',
    key: 'address.name',
    name: dictionary().ADDRESS,
    type: 'text',
    section: 'delivery',
  },
  {
    id: 'state',
    key: 'address.area.name',
    name: dictionary().STATE,
    type: 'text',
    section: 'delivery',
  },
  {
    id: 'city',
    key: 'address.area.city.name',
    name: dictionary().CITY,
    type: 'text',
    section: 'delivery',
  },
]

const NFCRequestDetails = () => {
  return (
    <ViewOne
      header={header}
      restRoute='/nfc-replacement-requests'
      dataLocation={''}
      title={dictionary().NFC_REQUESTS}
      cashingKey={CASHING_KEY.GET_SINGLE_NFC_REQUEST}
      userType='nfc-replacement-requests'
      table={{ render: NFCDetailsVehicleTable, dataPath: 'vehicles' }}
      customComponent={NFCRequestHistory}
    />
  )
}

export default NFCRequestDetails
