import React from 'react'

import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'crm_number',
    key: 'crm_number',
    name: dictionary().CMR_NUMBER,
    type: 'text',
  },
  {
    id: 'sales_person',
    key: 'sales_person',
    name: dictionary().SALES_PERSON,
    type: 'text',
  },
  {
    id: 'account_manager',
    key: 'account_manager',
    name: dictionary().ACCOUNT_MANAGER,
    type: 'text',
  },
  {
    id: 'invoiceType',
    key: 'invoice_type',
    name: dictionary().INVOICE_CUMTOMER_TYPE,
    type: 'text',
  },
  {
    id: 'settlement',
    key: 'settlement',
    name: dictionary().SETTLEMENT_TYPE,
    type: 'text',
  },
  {
    id: 'area',
    key: 'area',
    name: dictionary().AREA,
    type: 'text',
  },
]
const actions = [
  {
    type: 'path',
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'path',
    action: 'add-new-staff',
    title: dictionary().ADD_NEW_STAFF,
  },
  {
    type: 'delete',
    apiRoute: 'customers',
    redirectRoute: '/customers',
    title: dictionary().DELETE,
  },
]

const CustomerPage: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/customers'
      dataLocation={''}
      title={dictionary().CUSTOMERS}
      cashingKey={CASHING_KEY.GET_SINGLE_CUSTOMER}
      userType='customers'
    />
  )
}

export default CustomerPage
