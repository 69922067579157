import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../../dictionary'
import { OctaneTableBuilder } from '../../core/OctaneTable/OctaneTableBuilder'
import { Props } from './OctaneTableType'

const OctaneTable: FC<Props> = ({ specialFields, data, getHeaders, loading, tableProps }): React.ReactNode | any => {
  const HEADERS = getHeaders()
  const tableBuilder = new OctaneTableBuilder(data, HEADERS, tableProps)

  specialFields.forEach(({ key, render }) => {
    tableBuilder.addSpecialFields(key, render)
  })
  const tableToRender = tableBuilder.build()

  return data && !loading && data?.data?.length !== 0 ? (
    tableToRender
  ) : !loading && data && data?.data?.length == 0 ? (
    <Flex w='100%' h='100%' align='center' justify='center'>
      <Text fontSize='2xl'>
        {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().CAR_SERVICES_TRANSACTION}
      </Text>
    </Flex>
  ) : (
    <Flex w='100%' h='100%' align='center' justify='center'>
      <Text fontSize='2xl'>
        {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().CAR_SERVICES_TRANSACTION}
      </Text>
    </Flex>
  )
}

export default OctaneTable
