import React from 'react'
import { FormLabel, Box, FormLabelProps } from '@chakra-ui/react'
import { DateRangePicker } from 'rsuite'

import { addDays, endOfDay, endOfMonth, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns'
import dictionary from '../../dictionary'
import { useLocation } from 'react-router-dom'

interface Props {
  from: Date | any
  onChange: any
  to: any
  displayLabel?: boolean
  labelProps?: FormLabelProps
}
const ranges = [
  {
    label: 'الشهر الحالي',
    value: [startOfMonth(new Date()), endOfDay(new Date())],
  },
  {
    label: 'الشهر الماضي',
    value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
  },
  {
    label: 'أخر سبع أيام',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: 'امس',
    value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
  },
  {
    label: 'اليوم',
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
]

const DateRange: React.FC<Props> = ({ from, to, onChange, displayLabel = true, labelProps }) => {
  const { allowedMaxDays, combine, before }: any = DateRangePicker
  const { pathname } = useLocation()
  return (
    <div className='customDatePickerWidth' style={{ width: '100%', height: '76px' }}>
      {displayLabel && (
        <Box position='relative' right={'0px'}>
          <FormLabel fontSize='15px' fontWeight='600' color={'#1B202B'} whiteSpace='nowrap' {...labelProps}>
            {dictionary().CHOOSE_Date}
          </FormLabel>
        </Box>
      )}

      <DateRangePicker
        className='myDatePicker'
        dir='rtl'
        ranges={ranges as any}
        format='yyyy-MM-dd'
        onChange={onChange}
        value={[from, to]}
        preventOverflow={true}
        placement='bottomEnd'
        style={{
          width: '100%',
        }}
        cleanable={false}
        disabledDate={pathname === '/e-invoices' ? combine(allowedMaxDays(31), before(new Date('2024-07-26'))) : undefined}
      />
    </div>
  )
}

export default DateRange
