import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddCustomerVariables = {
  name: any
  settlement_type: any
  crm_number: any
}

const AddCustomer: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/customers', { data: vars }), {
    onSuccess: (data: any): void => {
      data?.data?.id ? push(`/charging-profiles/new?customerId=${data?.data?.id}`) : push('/customers')
      onSuccess()
    },
  })

  const AddCustomer = async (formData: AddCustomerVariables) => {
    formData.crm_number = +formData?.crm_number
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_CORPORATE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddCustomer(getValues() as AddCustomerVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddCustomer as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id='name' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.name}>
              <FormLabel>
                <>
                  {' '}
                  {dictionary().NAME} <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='name' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.crm_number}>
              <FormLabel>
                <>
                  {' '}
                  {dictionary().CMR_NUMBER} <Reqiured />
                </>
              </FormLabel>
              <Input
                type='number'
                {...register('crm_number', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                })}
              />
              <FormErrorMessage>{errors?.crm_number?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='name' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.sales_person}>
              <FormLabel>
                <>
                  {' '}
                  {dictionary().SALES_PERSON} <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('sales_person', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.sales_person?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='name' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.account_manager}>
              <FormLabel>
                <> {dictionary().ACCOUNT_MANAGER}</>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('account_manager', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.account_manager?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATIONS_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.invoice_type}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().INVOICE_CUMTOMER_TYPE}
                <Reqiured />
              </FormLabel>

              <Select
                dir='ltr'
                data-test={dictionary().INVOICE_CUMTOMER_TYPE}
                textAlign='right'
                {...register('invoice_type', {
                  required: dictionary().REQUIRED,
                })}
                placeholder={dictionary().INVOICE_CUMTOMER_TYPE}
              >
                <option value='INVOICE_PER_TAX_ID'>فاتورة لكل رقم ضريبي</option>
                <option value='INVOICE_PER_CORPORATE'>فاتورة لكل شركة</option>
              </Select>
              <FormErrorMessage>{errors?.invoice_type?.message} </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATIONS_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.settlement}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().SETTLEMENT_TYPE}
                <Reqiured />
              </FormLabel>

              <Select
                dir='ltr'
                data-test={dictionary().SETTLEMENT_TYPE}
                textAlign='right'
                {...register('settlement', {
                  required: dictionary().REQUIRED,
                })}
                placeholder={dictionary().SETTLEMENT_TYPE}
              >
                <option key={1} value='POST_PAID'>
                  {dictionary().POST_PAID}
                </option>
                <option key={2} value='PRE_PAID'>
                  {dictionary().PRE_PAID}
                </option>
              </Select>
              <FormErrorMessage>{errors?.settlement?.message} </FormErrorMessage>
            </FormControl>
            <FormControl id='area' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.area}>
              <FormLabel>
                <> {dictionary().AREA}</> <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('area', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.area?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddCustomer
