import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { clone, isEmpty } from 'ramda'
import { FC } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getMustSearch } from '../../constants/mustSearch'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { useStore } from '../../store'

type Prop = {
  qrCode: string
  errors: any
  register: any
  trigger: any
  getValues: any
  isTwoFactorEnabled: boolean
}

const OTPStep: FC<Prop> = ({ qrCode, errors, register, trigger, getValues, isTwoFactorEnabled }) => {
  const { setToken, setUserType, setRole, setUser } = useStore((state) => state)
  const { go } = useHistory()

  const { refetch } = useQuery(['admin'], () => fetch('GET', `/admin/current`), {
    enabled: false,
  })
  const {
    isLoading: OtpIsLoading,
    mutate: OtpMutate,
    error: OtpError,
  } = useMutation((vars: any) => fetch('POST', `/admin/verify-otp`, vars), {
    onSuccess: (data): void => {
      getTokenThenLogin(data)
    },
  })

  const verifyOtp = async () => {
    OtpMutate({ data: { otp: getValues()?.otp, username: getValues()?.username.trim().toLowerCase() } })
  }

  const getTokenThenLogin = (data: any) => {
    setToken(data.accessToken)
    setUserType('admin')
    refetch().then((res) => {
      if (!isEmpty(res)) {
        const mustSearch = getMustSearch(res?.data?.role)
        const roleData = clone(res.data)
        setRole(roleData.role)
        setUser({ ...roleData, userType: 'admin' })
        localStorage.setItem('activeRole', JSON.stringify({ ...roleData, userType: 'admin', mustSearch }))
      }
      go(0)
    })
  }

  return (
    <>
      {!isTwoFactorEnabled && (
        <>
          <ol>
            <li>
              <p>قم بتحميل احدى التطبيقات</p>
              <a target={'_blank'} href='https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en' rel='noreferrer'>
                Google Authenticator
              </a>
              <br />
              <a target={'_blank'} href='https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en' rel='noreferrer'>
                Microsoft Authenticator
              </a>
            </li>
            <li>يرجى مسح رمز الاستجابة السريعة هذا</li>
            <li>قم بإدخال كود OTP</li>
          </ol>
          <img src={qrCode} alt='QR Code' width={'100%'} />
        </>
      )}
      <FormControl id='otp' isInvalid={!!errors.otp || !!(OtpError as any)?.data?.message}>
        <FormLabel>{dictionary().OTP_CODE}</FormLabel>
        <Input
          dir='ltr'
          type='text'
          id='otp'
          autoComplete='otp'
          {...register('otp', {
            required: dictionary().REQUIRED,
          })}
        />
        <FormErrorMessage>{errors.otp?.message}</FormErrorMessage>
        <FormErrorMessage>{(OtpError as any)?.data?.message ? 'OTP غير صحيح' : ''}</FormErrorMessage>
      </FormControl>
      <Button
        data-test='sign-in-button'
        isLoading={OtpIsLoading}
        loadingText={dictionary().NEXT}
        colorScheme='blue'
        variant='solid'
        width='100%'
        marginTop={5}
        onClick={async () => (await trigger()) && verifyOtp()}
      >
        {dictionary().NEXT}
      </Button>
    </>
  )
}

export default OTPStep
