import React from 'react'
import tickGreen from '../../assets/tick-green.png'
import exMarkRed from '../../assets/ex-mark-red.png'
import exMarkYellow from '../../assets/ex-mark-yellow.png'
export enum STATUS {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  REJECTED = 'REJECTED',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
}

export enum DELIVERY_OPTION {
  ADDRESS = 'ADDRESS',
  OCTANE = 'OCTANE',
}

import { FC } from 'react'
import dictionary from '../../dictionary'
import { Flex, Text } from '@chakra-ui/react'
type Props = {
  status: string
  hasBackgroundColor?: boolean
}

const NFCStatusCell: FC<Props> = ({ status, hasBackgroundColor = true }) => {
  return (
    <Flex style={{ display: 'flex' }}>
      <Flex
        style={{
          ...styles.statusCellContainer,
          ...(hasBackgroundColor &&
            (status === STATUS.AWAITING_REVIEW
              ? styles.statusContainerBlue
              : status === STATUS.REJECTED
              ? styles.statusContainerRed
              : status === STATUS.DONE
              ? styles.statusContainerGreen
              : styles.statusContainerYellow)),
        }}
      >
        <img
          src={status === STATUS.DONE ? tickGreen : status === STATUS.IN_PROGRESS ? exMarkYellow : status === STATUS.REJECTED ? exMarkRed : exMarkYellow}
          style={{
            ...{
              width: '12px',
              height: '12px',
              marginLeft: '4px',
            },
            ...(status === STATUS.AWAITING_REVIEW
              ? {
                  filter: 'brightness(0) saturate(100%) invert(18%) sepia(90%) saturate(500%) hue-rotate(190deg)',
                }
              : {}),
          }}
        />
        <Text
          style={{
            ...styles.statusCellText,
            ...(status === STATUS.AWAITING_REVIEW
              ? styles.statusTextBlue
              : status === STATUS.REJECTED
              ? styles.statusTextRed
              : status === STATUS.DONE
              ? styles.statusTextGreen
              : styles.statusTextYellow),
          }}
        >
          {status === STATUS.AWAITING_REVIEW
            ? dictionary().NFC_STATUS_AWAITING_REVIEW
            : status === STATUS.REJECTED
            ? dictionary().NFC_STATUS_REJECTED
            : status === STATUS.DONE
            ? dictionary().NFC_STATUS_DONE
            : status === STATUS.DELIVERED
            ? dictionary().NFC_STATUS_DELIVERED
            : status === STATUS.READY_FOR_DELIVERY
            ? dictionary().NFC_STATUS_READY_FOR_DELIVERY
            : dictionary().NFC_STATUS_IN_PROGRESS}
        </Text>
      </Flex>
    </Flex>
  )
}

export default NFCStatusCell

// ----------------- Component style -----------------
const styles: StylesType = {
  statusCellContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '6px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '27px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  statusCellText: { fontSize: '12px' },
  statusContainerBlue: {
    backgroundColor: '#EFF8FF',
  },
  statusTextBlue: {
    color: '#175CD3',
  },
  statusContainerYellow: {
    backgroundColor: 'rgba(233, 159, 39, 0.2)',
  },
  statusTextYellow: {
    color: '#E99F27',
  },
  statusContainerGreen: {
    backgroundColor: '#D9F0E8',
  },
  statusTextGreen: {
    color: '#42B48B',
  },
  statusContainerRed: {
    backgroundColor: 'rgba(234, 118, 103, 0.2)',
  },
  statusTextRed: {
    color: '#C34545',
  },
}
// ---------------------------------------------------

type StylesType = {
  statusCellContainer: React.CSSProperties
  statusCellText: React.CSSProperties
  statusContainerBlue: React.CSSProperties
  statusTextBlue: React.CSSProperties
  statusContainerYellow: React.CSSProperties
  statusTextYellow: React.CSSProperties
  statusContainerGreen: React.CSSProperties
  statusTextGreen: React.CSSProperties
  statusContainerRed: React.CSSProperties
  statusTextRed: React.CSSProperties
}
