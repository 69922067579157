import React from 'react'
import AdvancedFilter from '../../components/core/advanced-filters/AdvancedFilter'
import OctanePage from '../../components/OctanePage/OctanePage/OctanePage'
import { MUTATION_METHOD } from '../../components/OctanePage/OctanePage/OctanePageType'
import dictionary from '../../dictionary'
import { useCarServicesConfig } from './useCarServicesConfig'

const CarServices: React.FC = () => {
  const { tags, searchFields, specialFields } = useCarServicesConfig()
  return (
    <OctanePage
      mutationEndpoint='/service-transaction'
      mutationMethod={MUTATION_METHOD.GET}
      specialFields={specialFields}
      hasHeader={dictionary().CAR_SERVICES_TRANSACTION}
      headerRouteTo=''
      hasTags={tags}
      hasAdvancedFilter={AdvancedFilter}
      hasSearchDate={true}
      hasPagination={true}
      hasSearch={false}
      hasSearchByKeys={searchFields}
      hasDownload={{
        has_csv: false,
        has_xlsx: false,
      }}
      tableProps={{
        rowStyles: {},
        cellStyles: {},
        selectable: true,
        showFirstCellBackground: true,
        containerProps: {
          maxH: '70vh',
        },
      }}
    />
  )
}

export default CarServices
