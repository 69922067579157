import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  onSelect: (val: any) => void
  label: string
  placeholder: string
  searchRoute: string
  singleRoute: string
  formFieldName: string
  selectedValue?: any
  isDisabled?: boolean
  valueKey?: string // e.g., 'id'
  displayKey?: string // e.g., 'name.ar'
  shouldUseFilterStore?: boolean
  setFilterStoreValue?: (value: any) => void
  filterStoreIds?: any[]
  dependentIds?: any[] // IDs that this filter depends on
  clearValueOnDependencyChange?: boolean
}

const FilterDropList: React.FC<Props> = ({
  onSelect,
  label,
  placeholder,
  searchRoute,
  singleRoute,
  formFieldName,
  isDisabled,
  valueKey = 'id',
  displayKey = 'name',
}) => {
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })

  const selectedItem = useRef({})
  selectedItem.current = watch(formFieldName, '')

  useEffect(() => {
    if (typeof selectedItem.current !== null && selectedItem.current !== undefined && selectedItem.current !== '') {
      const value = getValues()?.[formFieldName][0]
      onSelect(value)
    }
  }, [selectedItem.current])

  return (
    <FormControl id={label} w='100%' dir='rtl' isDisabled={isDisabled}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={formFieldName}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.[formFieldName]}
            onChange={onChange}
            value={value}
            searchRoute={searchRoute}
            singleRoute={singleRoute}
            valueFormatter={(option) => option?.[valueKey]}
            displayFormatter={(option) => {
              const keys = displayKey.split('.')
              return keys.reduce((obj, key) => obj?.[key], option)
            }}
            dataTest={placeholder}
          />
        )}
      />
    </FormControl>
  )
}

export default FilterDropList
