import { Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { Item, Menu, useContextMenu } from 'react-contexify'
// eslint-disable-next-line import/no-unresolved
import 'react-contexify/ReactContexify.css'
import { FuelTransaction } from '../../../types/FuelTransaction'
import { Property } from 'csstype'

type OctaneTableBodyProps = {
  data: FuelTransaction[]
  specialFields?: { key: string; headerFunction: (row: any) => React.ReactNode }[]
  headersList: string[] | React.ReactNode[]
  rowStyles?: React.CSSProperties
  onRowClick?: (row: any, index: number) => void
  cellStyles?: React.CSSProperties
  onCellClick?: (row: any, key: string, index: number) => void
  selectable?: boolean
  showFirstCellBackground?: boolean
}

const MENU_ID = 'copyMenuID'

const OctaneTableBody: FC<OctaneTableBodyProps> = ({
  data,
  specialFields,
  headersList,
  rowStyles,
  onRowClick,
  cellStyles,
  onCellClick,
  selectable = true,
  showFirstCellBackground = true,
}) => {
  const { show } = useContextMenu({
    id: MENU_ID,
  })

  function handleContextMenu(event: any) {
    show({
      event,
      props: {
        tdContent: event?.target?.childNodes?.[0]?.textContent,
      },
    })
  }

  const copyTextToClipboard = (e: any) => {
    // Extract the text content from the <td> element
    const textToCopy = e.props.tdContent

    // Create a temporary textarea element
    const textarea = document.createElement('textarea')
    textarea.value = textToCopy

    // Set the textarea to be invisible
    textarea.style.position = 'fixed'
    textarea.style.opacity = '0'

    // Append the textarea to the document
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    try {
      // Use the Clipboard API to write text to the clipboard
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {})
        .catch(() => {})
    } catch (err) {
    } finally {
      // Remove the textarea from the document
      document.body.removeChild(textarea)
    }
  }

  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)
  const ToggleIndex = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(null)
    } else {
      setSelectedIndex(index)
    }
  }
  const specialFieldsKeys = specialFields?.map((field) => field.key)
  return (
    <Tbody>
      <Menu id={MENU_ID}>
        <Item id='copy' onClick={copyTextToClipboard}>
          نسخ
        </Item>
      </Menu>

      {data.map((row: any, rowIndex) => (
        <Tr
          key={rowIndex}
          style={{
            backgroundColor: rowIndex === selectedIndex ? '#B39DDB' : 'unset',
            border: rowIndex === selectedIndex ? '2px solid #B39DDB' : 'none',
            color: rowIndex === selectedIndex ? '#4A148C' : 'black',
            fontWeight: rowIndex === selectedIndex ? 600 : 500,
            cursor: selectable ? 'pointer' : 'default',
            ...rowStyles,
          }}
          onClick={() => (onRowClick ? onRowClick(row, rowIndex) : null)}
        >
          {headersList?.map((headerKey: any, index) => {
            const keys = Object.keys(row)
            const defaultCellStyle: React.CSSProperties = {
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center' as Property.TextAlign,
              minWidth: 'max-content',
              width: 'max-content',
              backgroundColor: showFirstCellBackground && index === 0 ? '#DCD9F4' : 'unset',
              ...cellStyles,
            }

            const handleCellClick = () => {
              if (onCellClick) {
                onCellClick(row, headerKey, index)
              }
              if (index === 0 && selectable) {
                ToggleIndex(rowIndex)
              }
            }

            return keys.includes(headerKey) || specialFieldsKeys?.includes(headerKey) ? (
              specialFieldsKeys?.includes(headerKey) ? (
                <Td key={index} style={defaultCellStyle} onClick={handleCellClick}>
                  {specialFields?.find((field) => field.key === headerKey)?.headerFunction(row)}
                  {index === 0 && selectedIndex === rowIndex && (
                    <p style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize: '10px' }}> إلغاء الاختيار</p>
                  )}
                </Td>
              ) : (
                <Td key={index} style={defaultCellStyle} onContextMenu={handleContextMenu} onClick={handleCellClick}>
                  <Text align={'center'} sx={{ width: 'max-content', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                    {[undefined, null, ''].includes(row[headerKey]) ? '-' : row[headerKey]}
                  </Text>
                </Td>
              )
            ) : (
              <Td key={index} style={defaultCellStyle} onContextMenu={handleContextMenu}>
                <Text align={'center'} sx={{ width: 'max-content', textAlign: 'center' }}>
                  -
                </Text>
              </Td>
            )
          })}
        </Tr>
      ))}
    </Tbody>
  )
}

export default OctaneTableBody
