import { Box, GridItem, Stack, Text } from '@chakra-ui/react'
import { pathOr } from 'ramda'
import React from 'react'
import Card from '../../components/core/Card'
import Table from '../../components/core/Table'
import dictionary from '../../dictionary'

interface Props {
  data: any
}

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().CAR_ID,
    type: 'text',
  },
  {
    id: 'number_plate',
    key: 'number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'nfc_serial_number',
    key: 'nfc_serial_number',
    name: dictionary().NFC_NUMBER,
    type: 'text',
  },
]

const NFCDetailsVehicleTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().VEHICLES}
        </Text>
        <GridItem colSpan={6}>
          <Box w='100%'>
            <Stack>
              <GridItem>
                <Card h='100%' data-test='vehicles-table'>
                  <Table raised enableIdRedirect={false} height={350} headers={headers} data={data || []} />
                </Card>
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default NFCDetailsVehicleTable
