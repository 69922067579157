import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react'
import QRCode from 'qrcode'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import PasswordInput from '../../components/core/PasswordInput'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import OTPStep from './OTPStep'

type MutationLoginArgs = any

interface FormTypes {
  username: string
  password: string
  otp: string
}

const LoginForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm<FormTypes>()

  const { push } = useHistory()
  const { pathname } = useLocation()
  const [qrCode, setQrCode] = useState('')
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false)
  const route = pathname.split('/')[1]

  const generateQRCode = async (pin: string) => {
    try {
      const url = await QRCode.toDataURL(`otpauth://totp/Octane?secret=${pin}&issuer=Octane`)
      setQrCode(url)
    } catch (err) {
      console.error('Error generating QR code', err)
    }
  }

  const { isLoading, mutate, error } = useMutation((vars: MutationLoginArgs) => fetch('post', `${route}/sign-in-2fa`, vars), {
    onSuccess: (data): void => {
      if (!data.google_2fa_enabled) {
        setIsTwoFactorEnabled(false)
        generateQRCode(data.secret)
      } else {
        setIsTwoFactorEnabled(true)
      }
    },
  })

  const login = async (formData: FormTypes) => {
    mutate({ data: { ...formData, username: formData?.username.trim().toLowerCase() } })
  }

  return (
    <>
      {qrCode || isTwoFactorEnabled ? (
        <OTPStep qrCode={qrCode} errors={errors} register={register} trigger={trigger} getValues={getValues} isTwoFactorEnabled={isTwoFactorEnabled} />
      ) : (
        <form onSubmit={handleSubmit(login)}>
          <Box className='margin-100'>
            <FormControl id='username' isInvalid={!!errors.username}>
              <FormLabel>{dictionary().USERNAME}</FormLabel>
              <Input
                dir='ltr'
                type='text'
                id='username'
                autoComplete='username'
                {...register('username', {
                  required: dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id='password' isInvalid={!!errors.password}>
              <FormLabel>{dictionary().PASSWORD}</FormLabel>
              <PasswordInput
                inputProps={{
                  ...register('password', {
                    required: dictionary().REQUIRED,
                  }),
                  isInvalid: !!errors.password,
                  autoComplete: 'current-password',
                }}
              />
              <FormErrorMessage>{dictionary().REQUIRED}</FormErrorMessage>
            </FormControl>
            <p style={{ color: 'red' }}>{(error as any)?.data?.message && dictionary().INCORRECT_USERNAME_OR_PASSWORD}</p>
            <Button
              data-test='sign-in-button'
              isLoading={isLoading}
              loadingText={dictionary().LOGGINGIN}
              colorScheme='blue'
              variant='solid'
              width='100%'
              type='submit'
            >
              {dictionary().LOGIN}
            </Button>
            <Text className='margin-20' textAlign={'center'} onClick={() => push('/forget-password')} textDecor={'underline'} cursor={'pointer'}>
              نسيت كلمة المرور؟
            </Text>
          </Box>
        </form>
      )}
    </>
  )
}

export default LoginForm
