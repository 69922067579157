import { FC } from 'react'
import { Th } from '@chakra-ui/react'

// --------- the type and id props are retreaved here to be accessed from types as props.value ---------
export type OctaneHeaderProps = { value: string | number; style?: React.CSSProperties; type?: string; id?: string }
// -----------------------------------------------------------------------------------------------------
const OctaneHeader: FC<OctaneHeaderProps> = ({ value, style = { textAlign: 'center' }, type, id }) => {
  return (
    <Th position='sticky' top='0' bg='gray.50' zIndex='9' style={{ direction: 'rtl', minWidth: 'max-content', width: 'max-content', ...style }}>
      {value}
    </Th>
  )
}

export default OctaneHeader
