import dictionary from '../../../dictionary'

type option = {
  label: string
  value: string
  defaultChecked?: boolean
}

export type Detail = {
  name: string
  value: string
  type: string
  register: any
  options?: option[]
  disabled?: boolean
  label?: string
  required?: boolean
}
export type VersionData = {
  label: string
  details: Detail[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleVersionData = (versionData?: any, register?: any, isAfterTrail?: boolean, corporate?: any, getValues?: any): VersionData[] => {
  const tipsType = versionData?.tips_type === 'PERCENTAGE' ? dictionary().PERCENTAGE : versionData?.tips_type === 'UNIT' ? dictionary().PAISTRES_PER_LITER : ''

  return [
    {
      label: dictionary().VEHICLE_FEES,
      details: [
        {
          name: dictionary().SUBSCRIPTION_FEES,
          value: versionData?.vehicle_subscription_fees,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}vehicle_subscription_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().NFC_FEES,
          value: versionData?.nfc_fees,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}nfc_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().INSTALLATION_FEES,
          value: versionData?.installation_fees,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}installation_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().NFC_REPLACEMENT_COST,
          value: versionData?.nfc_replacement_fees,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}nfc_replacement_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().ROAD_ASSISTANCE_COST,
          value: versionData?.road_assistance_cost,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}road_assistance_cost`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
          }),
        },
        {
          name: dictionary().SUBSCRIPTION_TYPE,
          value: versionData?.subscription_type,
          type: 'radio',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}subscription_type`, {
            required: dictionary().REQUIRED,
          }),
          options: [
            {
              label: dictionary().MONTHLY,
              value: 'MONTHLY',
            },
            {
              label: dictionary().YEARLY,
              value: 'YEARLY',
            },
          ],
        },
      ],
    },
    {
      label: dictionary().TRANSACTION_FEES,
      details: [
        {
          name: dictionary().EXTERNAL_STATION,
          value: versionData?.fee_rate,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}fee_rate`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().INTERNAL_STATION,
          value: versionData?.internal_stations_fee_rate,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}internal_stations_fee_rate`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().FINANCING_FEES,
          value: versionData?.finance_rate,
          disabled: (corporate && corporate?.settlement === 'PRE_PAID') ?? false,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}finance_rate`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
      ],
    },
    {
      label: dictionary().TIPS_FEES,
      details: [
        {
          name: dictionary().TIPS_TYPE,
          value: tipsType ? tipsType : '-',
          type: 'radio',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}tips_type`, {
            required: dictionary().REQUIRED,
          }),
          options: [
            {
              label: dictionary().PERCENTAGE,
              value: 'PERCENTAGE',
            },
            {
              label: dictionary().PAISTRES_PER_LITER,
              value: 'UNIT',
            },
          ],
        },
        {
          name: dictionary().EXTERNAL_STATION_FEES,
          value: versionData?.tips_percentage,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}tips_percentage`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
      ],
    },
    {
      label: dictionary().FRUAD_FEES,
      details: [
        {
          name: dictionary().FRAUD_FEE_CONGIRATION,
          value: versionData?.fraud_fees_type,
          type: 'radio',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}fraud_fees_type`, {
            required: dictionary().REQUIRED,
          }),
          required: true,
          options: [
            {
              label: dictionary().PIASTERS_PER_LITER,
              value: 'PIASTERS_PER_LITER',
              defaultChecked: true,
            },
            {
              label: dictionary().PERCENTAGE_FROM_AMOUNT,
              value: 'PERCENTAGE',
              defaultChecked: false,
            },
            {
              label: dictionary().FIXED_AMOUNT,
              value: 'FIXED_AMOUNT',
              defaultChecked: false,
            },
          ],
        },
        {
          name: dictionary().FRUAD_FEES,
          value: versionData?.fraud_detection_fees,
          type: 'number',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}fraud_detection_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
            max: {
              value: 2000,
              message: dictionary().CANNOT_BE_MORE_THAN_2000,
            },
          }),
        },
        {
          name: dictionary().REFUNDED,
          label: dictionary().AUTO_REFUND,
          value: versionData?.auto_refund,
          disabled: getValues && getValues('bundle_type') === 1,
          type: 'checkbox',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}auto_refund`, {
            required: false,
          }),
        },
        {
          name: dictionary().FRAUD_MESSAGE,
          label: dictionary().SEND_FRAUD_MESSAGE,
          value: versionData?.enable_sms_service,
          disabled: getValues('bundle_type') !== 3,
          type: 'checkbox',
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}enable_sms_service`, {
            required: false,
          }),
        },
        {
          name: dictionary().FRAUD_MESSAGE_COST,
          value: versionData?.sms_service_fees,
          type: 'number',
          disabled: !getValues('enable_sms_service'),
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}sms_service_fees`, {
            required: dictionary().REQUIRED,
            min: {
              value: 0,
              message: dictionary().NO_NEGATIVE_NUMBER,
            },
          }),
        },
      ],
    },
    {
      label: dictionary().INVOICING_DETAILS,
      details: [
        {
          name: dictionary().INVOICE_PERIOD,
          value: dictionary()[versionData?.billing_period as keyof typeof dictionary],
          type: 'select',
          disabled: false,
          register: register?.(`${isAfterTrail ? 'after_trial_profile-' : ''}billing_period`, {
            required: dictionary().REQUIRED,
          }),
          options: [
            {
              label: dictionary().MONTHLY,
              value: 'MONTHLY',
            },
            {
              label: dictionary().WEEKLY,
              value: 'WEEKLY',
            },
            {
              label: dictionary().BIWEEKLY,
              value: 'BIWEEKLY',
            },
            // {
            //   label: dictionary().TEN_DAYS,
            //   value: 'TEN_DAYS',
            // },
          ],
        },
      ],
    },
  ]
}
